import React, { Fragment } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

import styles from "./best-banks.module.scss";

const BestBanks = (props) => {
  // image is 500x643 px
  // const classNameFromProps = props.imageClass;

  const imageData = useStaticQuery(graphql`
    {
      bestBanksImage: file(relativePath: { eq: "newsweek-3yr-lockup-600.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 380
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const BestBanksImage = () => {
    return props.useFixedImage ? (
      <StaticImage
        src="../../images/newsweek-3yr-lockup-600.png"
        alt="WaFd Bank voted Best Bank by Newsweek"
        placeholder="blurred"
        loading="eager"
        quality="100"
        width={360}
        height={225}
        imgClassName={props.imageClass}
      />
    ) : (
      <GatsbyImage
        image={imageData.bestBanksImage.childImageSharp.gatsbyImageData}
        alt="WaFd Bank voted Best Bank by Newsweek"
      />
    );
  };

  const ImgCol = () => (
    <div className={`${props.imageColumnClass}`}>
      <div className="text-center mx-auto">
        {props.useLink ? (
          <Link to="/about-us/best-bank" id="clickable-best-banks-image">
            <BestBanksImage />
            <span className="sr-only">America's Best Bank 2021, 2022 and 2023 - Newsweek</span>
          </Link>
        ) : (
          <BestBanksImage />
        )}
      </div>
    </div>
  );

  return (
    <section
      id="best-banks-section"
      className={`${props.sectionClass} ${props.linearGradientBg ? `${styles.bestBanksBg}` : ""}`}
    >
      <div className={`container ${props.containerClass}`}>
        <div className="row">
          {props.imagePosition && props.imagePosition === "left" && <ImgCol />}
          <div className={`${props.columnClass}`}>
            {props.components &&
              props.components.map((component, index) => {
                return <Fragment key={index}>{component}</Fragment>;
              })}
          </div>
          {props.imagePosition && props.imagePosition === "right" && <ImgCol />}
        </div>
      </div>
    </section>
  );
};
export default BestBanks;

BestBanks.defaultProps = {
  imagePosition: "left", // "left" or "right"
  linearGradientBg: false,
  sectionClass: null, // "bg-light", etc.
  containerClass: null, // "border-bottom", etc.
  columnClass: "col-sm-12 col-md-8 col-lg-9 my-auto", // "border-left", etc.
  imageColumnClass: "col-sm-12 col-md-4 col-lg-3", // "border-right", etc.
  imageClass: null,
  useLink: true, // make the image a link to "/about-us/best-banks"
  useFixedImage: false, // uses fluid image if false
  components: []
};
