import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

//components
import SecondaryLanding from "../components/layouts/secondary-landing";
import HeroChevron from "../components/hero/hero-chevron";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import SEO from "../components/seo/seo";
import StretchedLinkCards from "../components/card/stretched-link-cards";
import Icon from "../components/custom-widgets/icon";

import getHeroImgVariables from "../helpers/getHeroImgVariables";
import BestBanks from "../components/best-banks/best-banks";
import QuickLinks from "../components/quick-links/quick-links";

import useCardBlogsData from "../hooks/use-card-blogs-data";
import getFeaturedCardBlogData from "../helpers/getFeaturedCardBlogData";

const Articles = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/money-tips/hero-blogs-04272023-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/money-tips/hero-blogs-04272023-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/money-tips/hero-blogs-04272023-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/money-tips/hero-blogs-04272023-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/money-tips/hero-blogs-04272023-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/money-tips/hero-blogs-04272023-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/money-tips/hero-blogs-04272023-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
      securityPrivacy01: file(relativePath: { eq: "cards/articles-cards/thumbnail-detect-scams-073123.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const heroChevronData = {
    id: "blog-hero",
    ...getHeroImgVariables(imgData),
    altText: "Man sitting on stairs outside using his smartphone.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Need Money Tips? We've got your back."
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn",
            text: "See latest money tips",
            url: "#latest-money-tips-section",
            class: "btn-white",
            type: "anchor-link"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "Money Tips"
    }
  ];

  const SEOData = {
    title: "Personal, Business, and Commercial Finance Blogs to Follow",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "Personal, Business, and Commercial Finance Blogs to Follow"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Learn from and share blogs that cover a variety of financial topics spanning from personal financing to commercial banking."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/blog"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-blogs-04272023.jpg"
      }
    ]
  };

  // MINIBAND
  const minibandDataArticles = {
    objects: [
      {
        iconSource: "../../images/icons/icon-banking-101-80.svg",
        iconSourceXs: "../../images/icons/icon-banking-101-80.svg",
        hoverIconSource: "../../images/icons/icon-banking-hover-101-80.svg",
        text: "Banking 101",
        url: "/blog/banking-101",
        id: "miniband-item-0"
      },
      {
        iconSource: "../../images/icons/building-remodeling-hover-80.svg",
        iconSourceXs: "../../images/icons/building-remodeling-hover-80.svg",
        hoverIconSource: "../../images/icons/icon-building-remodeling-hover-80.svg",
        text: "Building and Remodeling",
        url: "/blog/building-remodeling",
        id: "miniband-item-1"
      },
      {
        iconSource: "../../images/icons/icon-building-credit-80.svg",
        iconSourceXs: "../../images/icons/icon-building-credit-80.svg",
        hoverIconSource: "../../images/icons/icon-building-credit-hover-80.svg",
        text: "Building Credit",
        url: "/blog/building-credit",
        id: "miniband-item-2"
      },
      {
        iconSource: "../../images/icons/icon-community-support-80.svg",
        iconSourceXs: "../../images/icons/icon-community-support-80.svg",
        hoverIconSource: "../../images/icons/icon-community-support-hover-80.svg",
        text: "Community Support",
        url: "/blog/community-support",
        id: "miniband-item-3"
      },
      {
        iconSource: "../../images/icons/icon-consumer-tips-80.svg",
        iconSourceXs: "../../images/icons/icon-consumer-tips-80.svg",
        hoverIconSource: "../../images/icons/icon-consumer-tips-hover-80.svg",
        text: "Consumer Tips",
        url: "/blog/consumer-tips",
        id: "miniband-item-4"
      },
      {
        iconSource: "../../images/icons/icon-family-finance-80.svg",
        iconSourceXs: "../../images/icons/icon-family-finance-80.svg",
        hoverIconSource: "../../images/icons/icon-family-finance-hover-80.svg",
        text: "Family Finance",
        url: "/blog/family-finance",
        id: "miniband-item-5"
      },
      {
        iconSource: "../../images/icons/icon-home-ownership-80.svg",
        iconSourceXs: "../../images/icons/icon-home-ownership-80.svg",
        hoverIconSource: "../../images/icons/icon-home-ownership-hover-80.svg",
        text: "Home Ownership",
        url: "/blog/home-ownership",
        id: "miniband-item-6"
      },
      {
        iconSource: "../../images/icons/icon-security-privacy-80.svg",
        iconSourceXs: "../../images/icons/icon-security-privacy-80.svg",
        hoverIconSource: "../../images/icons/icon-security-privacy-hover-80.svg",
        text: "Security and Privacy",
        url: "/blog/security-privacy",
        id: "miniband-item-7"
      },
      {
        iconSource: "../../images/icons/business-icon-active-44.svg",
        iconSourceXs: "../../images/icons/business-icon-active-44.svg",
        hoverIconSource: "../../images/icons/business-icon-hover-70.svg",
        text: "Small Business",
        url: "/blog/small-business",
        id: "miniband-item-8"
      }
    ]
  };

  // QUICK LINKS
  const responsiveQuickLinksObj = [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: minibandDataArticles.objects.length,
        slidesToScroll: minibandDataArticles.objects.length
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: minibandDataArticles.objects.length,
        slidesToScroll: minibandDataArticles.objects.length
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: minibandDataArticles.objects.length - 3,
        slidesToScroll: minibandDataArticles.objects.length - 3
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: minibandDataArticles.objects.length - 5,
        slidesToScroll: minibandDataArticles.objects.length - 5
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: minibandDataArticles.objects.length - 6,
        slidesToScroll: minibandDataArticles.objects.length - 6
      }
    },
    {
      breakpoint: 375,
      settings: {
        slidesToShow: minibandDataArticles.objects.length - 7,
        slidesToScroll: minibandDataArticles.objects.length - 7
      }
    }
  ];

  // LATEST MONEY TIPS
  const latestMoneyTipsCardsData = useCardBlogsData([
    "/blog/home-ownership/easy-move-cross-collateral",
    "/blog/banking-101/choosing-a-bank",
    "/blog/security-privacy/money-safety-in-banks"
  ]);
  const latestMoneyTipsData = {
    sectionClass: "bg-light",
    sectionId: "latest-money-tips-section",
    titleClass: "text-center",
    title: "Our Latest Money Tips",
    hasGrowEffect: true,
    cards: getFeaturedCardBlogData(latestMoneyTipsCardsData, {
      idString: "latest-money-tips-card-0",
      hasText: false
    }).map((card) => ({ ...card, titleClass: "text-green-60" }))
  };

  // BEST BANKS
  const bestBanksData = {
    sectionClass: "section-padding-top",
    columnClass: "col-sm-12 col-md-8 col-lg-9 my-auto border-md-left",
    components: [
      <h2>Awarded America's Best Big Bank by Newsweek</h2>,
      <div className="lead">Working together isn't new for us.</div>,
      <p className="text-larger">
        From digital options, touchless transactions and increased non-profit contributions to help our neighbors, we're
        still here for YOU. Our bankers approved more than 6,500 PPP loans to keep small businesses going in the
        pandemic.
      </p>,
      <p className="text-larger">Best Big Bank in Idaho, Washington, New Mexico</p>,
      <a
        href="https://www.newsweek.com/rankings/americas-best-banks-2023/best-big-bank-2023"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://www.newsweek.com/rankings/americas-best-banks-2023/best-big-bank-2023
      </a>
    ]
  };

  // BANKING 101
  const banking101CardsData = useCardBlogsData([
    "/blog/banking-101/boost-your-financial-knowledge",
    "/blog/banking-101/high-yield-money-market-or-certificate-of-deposit-cd",
    "/blog/banking-101/how-to-endorse-a-check"
  ]);
  const banking101Data = {
    sectionClass: "bg-white border-bottom",
    sectionId: "banking-101-section",
    titleClass: "text-center",
    title: "Money Tips for Banking 101",
    hasGrowEffect: true,
    linkText: "See All Banking 101 Articles",
    link: "/blog/banking-101",
    linkId: "more-articles-banking-101",
    cards: getFeaturedCardBlogData(banking101CardsData, { idString: "banking-101-card-0", hasText: false }).map(
      (card) => ({ ...card, titleClass: "text-green-60" })
    )
  };

  // BUILDING AND REMODELING
  const buildingRemodelingCardsData = useCardBlogsData([
    "/blog/building-remodeling/3-things-know-custom-construction-loan",
    "/blog/building-remodeling/pros-cons-before-buying-fixer-upper",
    "/blog/building-remodeling/building-vs-buying-house"
  ]);
  const buildingRemodelingData = {
    sectionClass: "bg-white border-bottom",
    sectionId: "building-remodeling-section",
    titleClass: "text-center",
    title: "Money Tips for Building and Remodeling",
    hasGrowEffect: true,
    linkId: "more-articles-building-remodeling",
    linkText: "See All Building and Remodeling Articles",
    link: "/blog/building-remodeling",
    cards: getFeaturedCardBlogData(buildingRemodelingCardsData, {
      idString: "building-remodeling-card-0",
      hasText: false
    }).map((card) => ({ ...card, titleClass: "text-green-60" }))
  };

  // BUILDING CREDIT
  const buildingCreditCardsData = useCardBlogsData([
    "/blog/building-credit/things-to-consider-before-cancel-credit-card",
    "/blog/building-credit/how-to-avoid-delinquent-credit-card-payments",
    "/blog/building-credit/how-to-get-out-of-credit-card-debt"
  ]);
  const buildingCreditData = {
    sectionClass: "bg-white border-bottom",
    sectionId: "building-credit-section",
    titleClass: "text-center",
    title: "Money Tips for Building Credit",
    hasGrowEffect: true,
    linkId: "more-articles-building-credit",
    linkText: "See All Building Credit Articles",
    link: "/blog/building-credit",
    cards: getFeaturedCardBlogData(buildingCreditCardsData, { idString: "building-credit-card-0", hasText: false }).map(
      (card) => ({ ...card, titleClass: "text-green-60" })
    )
  };

  // COMMUNITY SUPPORT
  const wafdCommunitySupportCardsData = useCardBlogsData([
    "/blog/community-support/moving-to-salt-lake-city-utah",
    "/blog/community-support/living-in-boise-idaho",
    "/blog/community-support/financial-inclusion"
  ]);
  const wafdCommunitySupportData = {
    sectionClass: "bg-white",
    sectionId: "community-support-section",
    titleClass: "text-center",
    title: "WaFd Community Support",
    hasGrowEffect: true,
    linkId: "more-articles-community-support",
    linkText: "See All Community Support Articles",
    link: "/blog/community-support",
    cards: getFeaturedCardBlogData(wafdCommunitySupportCardsData, {
      idString: "community-support-card-0",
      hasText: false
    }).map((card) => ({ ...card, titleClass: "text-green-60" }))
  };

  // FAMILY FINANCE
  const familyFinanceCardsData = useCardBlogsData([
    "/blog/family-finance/saving-for-college",
    "/blog/family-finance/ideas-financial-literacy-kids",
    "/blog/family-finance/questions-to-ask-before-taking-out-student-loan"
  ]);
  const familyFinanceData = {
    sectionClass: "bg-white border-bottom",
    sectionId: "family-finance-section",
    titleClass: "text-center",
    title: "Money Tips for Family Finance",
    hasGrowEffect: true,
    linkId: "more-articles-family-finance",
    linkText: "See All Family Finance Articles",
    link: "/blog/family-finance",
    cards: getFeaturedCardBlogData(familyFinanceCardsData, { idString: "family-finance-card-0", hasText: false }).map(
      (card) => ({ ...card, titleClass: "text-green-60" })
    )
  };

  // HOME OWNERSHIP
  const homeOwnershipCardsData = useCardBlogsData([
    "/blog/home-ownership/closing-costs",
    "/blog/home-ownership/best-way-to-buy-a-house",
    "/blog/home-ownership/selling-your-home"
  ]);
  const homeOwnershipData = {
    sectionClass: "bg-white border-bottom",
    sectionId: "home-ownership-section",
    titleClass: "text-center",
    title: "Money Tips for Home Ownership",
    hasGrowEffect: true,
    linkId: "more-articles-home-ownership",
    linkText: "See All Home Ownership Articles",
    link: "/blog/home-ownership",
    showIcon: false,
    cards: getFeaturedCardBlogData(homeOwnershipCardsData, { idString: "home-ownership-card-0", hasText: false }).map(
      (card) => ({ ...card, titleClass: "text-green-60" })
    )
  };

  // SECURITY AND PRIVACY
  const securityPrivacyCardsData = useCardBlogsData([
    "/blog/security-privacy/how-to-identify-prevent-scams-before-they-happen",
    "/blog/security-privacy/how-to-protect-yourself-online",
    "/blog/security-privacy/prevent-fraudulent-tax-filing"
  ]);
  const securityPrivacyData = {
    sectionClass: "bg-white border-bottom",
    sectionId: "security-privacy-section",
    titleClass: "text-center",
    title: "Money Tips for Security and Privacy",
    hasGrowEffect: true,
    linkId: "more-articles-security-privacy",
    linkText: "See All Security and Privacy Articles",
    link: "/blog/security-privacy",
    cards: getFeaturedCardBlogData(securityPrivacyCardsData, {
      idString: "security-privacy-card-0",
      hasText: false
    }).map((card) => ({ ...card, titleClass: "text-green-60" }))
  };

  // SMALL BUSINESS
  const smallBusinessCardsData = useCardBlogsData([
    "/blog/small-business/what-is-the-best-bank-for-a-small-business",
    "/blog/small-business/what-is-needed-to-open-business-bank-account",
    "/blog/small-business/small-business-cybersecurity-tips"
  ]);
  const smallBusinessData = {
    sectionClass: "bg-white",
    sectionId: "small-business-section",
    titleClass: "text-center",
    title: "Money Tips for Small Business",
    hasGrowEffect: true,
    linkId: "more-articles-small-business",
    linkText: "See All Small Business Articles",
    link: "/blog/small-business",
    cards: getFeaturedCardBlogData(smallBusinessCardsData, { idString: "small-business-card-0", hasText: false }).map(
      (card) => ({ ...card, titleClass: "text-green-60" })
    )
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section id="money-tips-title" className="container">
        <h1 className="text-center mb-0">WaFd Money Tips</h1>
      </section>
      <QuickLinks
        id="money-tips-categories"
        quickLinksData={minibandDataArticles.objects}
        quickLinksSpanishData={[]}
        responsive={responsiveQuickLinksObj}
      />
      <StretchedLinkCards {...latestMoneyTipsData} />
      <BestBanks {...bestBanksData} />
      <StretchedLinkCards {...banking101Data} />
      <StretchedLinkCards {...buildingRemodelingData} />
      <StretchedLinkCards {...buildingCreditData} />
      <StretchedLinkCards {...wafdCommunitySupportData} />
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <GatsbyImage
                image={imgData.securityPrivacy01.childImageSharp.gatsbyImageData}
                alt="Concerned woman looking at a smartphone sitting on a couch."
              />
            </div>
            <div className="col-md-6 mt-3 mt-md-0">
              <h2>Consumer Tips</h2>
              <h5>How to Identify Scams and Prevent Them</h5>
              <p>Learn seven signs of a scam, what to do if you spot a scam, and steps to take if you were scammed.</p>
              <div className="row">
                <div className="col-sm-8 col-md col-lg-9 col-xl-7">
                  <div>
                    <Link
                      id="consumer-tips-article"
                      to="/blog/security-privacy/how-to-identify-prevent-scams-before-they-happen"
                      className="btn btn-primary btn-block"
                    >
                      Read Article
                    </Link>
                  </div>
                  <div className="mt-4">
                    <Link
                      id="more-consumer-tips-articles-link"
                      className="btn btn-link btn-block"
                      to="/blog/consumer-tips"
                    >
                      See all Consumer Tips Articles
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <StretchedLinkCards {...familyFinanceData} />
      <StretchedLinkCards {...homeOwnershipData} />
      <StretchedLinkCards {...securityPrivacyData} />
      <StretchedLinkCards {...smallBusinessData} />
      <section className="bg-success">
        <div className="container">
          <div className="row d-block d-md-none text-center">
            <Icon name="map-marker-alt" lib="fas" class="mb-3 h3-font-size text-white" />
            <h3 className="text-white font-weight-semi-bold">Find your local WaFd Bank Branch</h3>
          </div>
          <div className="row d-none d-md-block">
            <div className="col-lg-7 d-flex flex-column flex-md-row offset-lg-1">
              <Icon name="map-marker-alt" lib="fas" class="mr-3 mt-1 h3-font-size text-white" />
              <h3 className="text-white">Find your local WaFd Bank Branch</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 offset-lg-1 pl-md-5">
              <h5 className="text-white mb-md-0">
                We're nearby, and we'll sit down with you and answer any questions you may have.
              </h5>
            </div>
            <div className="col-md-5 row no-gutters">
              <div className="col-lg-auto mb-4 mb-lg-0 mr-lg-3">
                <Link
                  to="/locations"
                  id="blog-contact-card-locations-cta"
                  className="btn btn-white no-min-width w-100 w-lg-auto"
                >
                  Find a Local Branch
                </Link>
              </div>
              <div className="col-lg-4">
                <Link
                  to="/about-us/contact-us"
                  className="btn btn-light no-min-width w-100 w-lg-auto"
                  id="blog-contact-card-contact-us-page-link"
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default Articles;
